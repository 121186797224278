import axios from 'axios';
import Toast from 'Components/Toast';

const api = axios.create();
api.defaults.baseURL = process.env.REACT_APP_REST_ENDPOINT;
api.defaults.headers.post['Content-Type'] = 'application/json';
api.defaults.headers.post['Accept'] = 'application/json';
console.log("REACT_APP_REST_ENDPOINT = " + process.env.REACT_APP_REST_ENDPOINT);
console.log("baseURL = " + api.defaults.baseURL);

function onError(response) {
  Toast.error(response?.response?.data);
  return response;
}

function onSuccess(response) {
  if (response.data.code === 401) {
    localStorage.removeItem('token');
    window.location.reload();
  } else {
    return response.data;
  }
}

export const setToken = (token) => {
  if (token) api.defaults.headers.common = { Authorization: `${token}` };
  else delete api.defaults.headers.common['Authorization'];
};

export const Services = {
  test: data => api.post('/admin/', data).then(onSuccess, onError),
  login: data => api.post('/admin/login', data).then(onSuccess, onError),
  dashboard: data => api.post('/admin/dashboard', data).then(onSuccess, onError),
  videoData: data => api.post('/admin/video-data', data).then(onSuccess, onError),
  saveVideoData: data => api.post('/video', data).then(onSuccess, onError),
  saveVideoFiles: data => api.post(`/video-files/${data.id}`, data.data).then(onSuccess, onError),
  deleteVideo: data => api.post(`/admin/delete-video`,data).then(onSuccess, onError),
};
