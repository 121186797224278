import { useEffect, useState } from 'react';
import { Layout, Menu} from 'antd';
import { DashboardOutlined, SettingOutlined, NodeIndexOutlined,ToTopOutlined,EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import './style.css';

const { Content } = Layout;

const MainLayout = ({ children }) => {
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState('/dashboard');

  useEffect(() => {
    const openKeys = sessionStorage.getItem('openKeys');
    const selectedMenu = sessionStorage.getItem('selectedMenu');
    setOpenKeys(JSON.parse(openKeys));
    setSelectedMenu(selectedMenu === '/logout' ? '/dashboard' : selectedMenu);
  }, []);


  const handleClick = menu => {
    if (menu.key === '/logout') {
      handleLogout();
    } else history.push(menu.key);
  };

  const updateOpenMenuKeys = openKeys => {
    setOpenKeys(openKeys);
    sessionStorage.setItem('openKeys', JSON.stringify(openKeys));
  };

  const updateSelectedMenu = selectedMenu => {
    setSelectedMenu(selectedMenu.key);
    sessionStorage.setItem('selectedMenu', selectedMenu.key);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    sessionStorage.clear();
    history.replace('/');
  };

  return (
    <Layout id="main-layout">
      <Content style={{ padding: '0 0px' }}>
        <Layout>
          <Menu
            id="style-3"
            mode="horizontal"
            onClick={handleClick}
            defaultSelectedKeys={['/dashboard']}
            defaultOpenKeys={[]}
            openKeys={openKeys}
            selectedKeys={selectedMenu}
            onOpenChange={updateOpenMenuKeys}
            onSelect={updateSelectedMenu}
            theme="dark"
          >
            <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
              Dashboard
              <ToTopOutlined style={{ padding: '10px' }} onClick={()=>{
                window.open('/dashboard')
              }}/>
            </Menu.Item>

            <Menu.Item key="/video-edit" icon={<NodeIndexOutlined />}>
              Video Editor
              <ToTopOutlined style={{ padding: '10px' }} onClick={()=>{
                window.open('/video-edit')
              }}/>
            </Menu.Item>
            <Menu.Item key="/video-edit-svg" icon={<NodeIndexOutlined />}>
              Video Editor SVG
              <EditOutlined style={{ padding: '10px' }} onClick={()=>{
                window.open('/video-edit-svg')
              }}/>
            </Menu.Item>

            <Menu.Item key="/logout" icon={<SettingOutlined />}>
              Logout
            </Menu.Item>
          </Menu>
          <Content style={{ background: '#fff' }}>{children}</Content>
        </Layout>
      </Content>
    </Layout>
  );
};

export default MainLayout;
