import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'Components/PrivateRoutes/PrivateRoutes';
import NotFound from 'Components/Default/NotFound';
import MainLayout from 'Components/Layouts/MainLayout';

const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ 'Pages/Auth/Login'));
const DashboardPage = lazy(() => import(/* webpackChunkName: "DashboardPage" */ 'Pages/Dashboard/Page'));
const VideoEditorPage = lazy(() => import(/* webpackChunkName: "VideoEdiorPage" */ 'Pages/VideoEditor/Page'));
const VideoSvgEditorPage = lazy(() => import(/* webpackChunkName: "VideoEdiorPage" */ 'Pages/VideoEditor/SvgPage'));


const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact={true}>
        <LoginPage />
      </Route>
      <PrivateRoute path="/">
        <MainLayout>
          <Switch>
            <PrivateRoute path="/dashboard" exact={true}>
              <DashboardPage />
            </PrivateRoute>
            <PrivateRoute path="/video-edit" exact={true}>
              <VideoEditorPage />
            </PrivateRoute>
            <PrivateRoute path="/video-edit-svg" exact={true}>
              <VideoSvgEditorPage />
            </PrivateRoute>
            <Route component={NotFound} />
          </Switch>
        </MainLayout>
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
