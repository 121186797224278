import { useEffect, useState } from 'react';
import { Route as ReactRoute, Redirect } from 'react-router-dom';

const PrivateRoute = ({ children, ...routeProps }) => {
  const [auth, setAuth] = useState(true);
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      setAuth(false);
    }
  }, []);
  return (
    <ReactRoute {...routeProps}>
      {
        {
          true: children,
          false: <Redirect to={{ pathname: '/' }} />,
        }[auth]
      }
    </ReactRoute>
  );
};

export default PrivateRoute;
